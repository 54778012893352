export function back() {
	const routerEl = document.querySelector("ion-router");
	if (routerEl) {
		routerEl.back();
	}
}

export function redirect(url: string) {
	const routerEl = document.querySelector("ion-router");

	if (routerEl) {
		routerEl.push(url);
	}
}
